import React from 'react';
import LocationInMap from '../Components/Map/LocationInMap';
import Contact from '../Components/Contact/Contact';
import Department from '../Components/Department/Department';
import Appointment from '../Components/Appointment/Appointment';
import About from '../Components/About/About';
import Iconbox from '../Components/Iconbox/Iconbox';
import PostWrapper from '../Components/Post/PostWrapper';
import SpecialistsSlider from '../Components/Slider/SpecialistsSlider';
import TestimonialSlider from '../Components/Slider/TestimonialSlider';
import BrandSlider from '../Components/Slider/BrandSlider';
import Newsletter from '../Components/Newsletter/Newsletter';
import Accordion from '../Components/Accordion/Accordion';
import PriceSlider from '../Components/Slider/PriceSlider';
import Funfact from '../Components/Funfact/Funfact';
import BeforeAfter from '../Components/BeforeAfter/BeforeAfter';
import MasonryGallery from '../Components/Gallery/Gallery';
import Hero4 from '../Components/Hero/Hero4';

const heroData = {
  bgImg: 'images/hero-bg3.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
  ],
  title: ['Histopatologi', 'Immunohistokimia', 'Pap Smear', 'Sitologi Cairan / Swab', 'FNAB (Biopsi Aspirasi Jarum Halus)', 'Riset'],
};

const mapLocationURL =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3973.9152339894245!2d119.50727307474169!3d-5.117361994859703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dbefde5d9b06667%3A0x851300348acd0eba!2siniaku!5e0!3m2!1sid!2ssg!4v1735820765377!5m2!1sid!2ssg';
// <div style="overflow:hidden;max-width:100%;width:500px;height:500px;"><div id="embed-map-canvas" style="height:100%; width:100%;max-width:100%;"><iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=wellpath+clinic+makassar&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="googl-ehtml" href="https://www.bootstrapskins.com/themes" id="authorize-maps-data">premium bootstrap themes</a><style>#embed-map-canvas .text-marker{}.map-generator{max-width: 100%; max-height: 100%; background: none;</style></div>
const Home4 = () => {
  return (
    <>
      <Hero4 data={heroData} />
      {/* <Iconbox data={iconboxData} />
      <About data={aboutData} /> */}
      <Department />
      {/* <Appointment />
      <SpecialistsSlider data={specialistData} />
      <hr />
      <MasonryGallery />
      <BeforeAfter data={beforeAfterData} />
      <TestimonialSlider data={testimonialData} /> */}
      <Funfact />
      {/* <PriceSlider data={priceData} />
      <Accordion data={faqData} />
      <Newsletter data={newsletterData} />
      <PostWrapper data={postData} />
      <BrandSlider data={brandData} />
      <Contact /> */}
      <LocationInMap data={mapLocationURL} />
    </>
  );
};

export default Home4;
